import React, { FC } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel as ReactCarousel } from "react-responsive-carousel";

export interface CarouselProps {
  interval?: number;
  swipeable?: boolean;
  emulateTouch?: boolean;
}

export const Carousel: FC<CarouselProps> = ({
  interval,
  children,
  swipeable,
  emulateTouch,
}) => (
  <ReactCarousel
    showStatus={false}
    showArrows={false}
    showThumbs={false}
    autoPlay={true}
    infiniteLoop={true}
    interval={interval}
    swipeable={swipeable}
    emulateTouch={emulateTouch}
  >
    {children}
  </ReactCarousel>
);
